import React from "react";
import PropTypes from "prop-types";

import SingleBlog from "./SingleBlog";
import Color from '../../constant/colors';

const Blogs = ({ blogData }) => {
  return (
    <section className="blogs">
      <div className="blog-banner">
        <h1>Our Blogs</h1>
      </div>
      <div className="r">
        {blogData &&
          blogData.map(({ node }, index) => <SingleBlog key={index} blog={node} backgroundColor={Color} index={index} />)}
      </div>
    </section>
  )
}
Blogs.propTypes = {
  blogData: PropTypes.array,
}
export default Blogs
