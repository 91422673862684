import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Image from "gatsby-image"

const SingleBlog = ({ blog, backgroundColor, index }) => {
  const {
    title,
    description: { description },
    by,
    slug,
    hoverImage,
  } = blog

  const replaceHtmlContent = () => {
    let shortDescription = description.substr(0, description.indexOf("."))
    shortDescription = shortDescription.toString();
    shortDescription = shortDescription.replace(/<[^>]*>/g, '');
    return shortDescription;
  };
  return (
    <div
      className="blog-row"
      style={{ backgroundColor: backgroundColor[index] }}
      key={index}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="blog-content">
              <h2 className="blog-title">{title}</h2>
              <h6 className="blog-by">{by}</h6>
              <p>{replaceHtmlContent()}</p>
              <Link
                to={`/blog/${slug}`}
                className="btn btn-default btn-outline-light"
              >
                Explore Blog
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="blog_img">
              <Image
                fluid={hoverImage[0].fluid}
                alt="Blog"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SingleBlog.propTypes = {
  blog: PropTypes.object,
}

export default SingleBlog
