import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Blogs from '../components/Blog/Blogs'

const blog = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" description="Blog description" />
      <Blogs blogData={data.blog.edges} />
    </Layout>
  )
}

export const allblogQuery = graphql`
query{
  blog:allContentfulBlog{
    edges{
      node{
        title
        by
        slug
        description {
          description
        }
        hoverImage{
          fluid{
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
}`;
export default blog
