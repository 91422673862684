export default [
    '#009aa8',
    '#ffb014',
    '#f8a602',
    '#e38b83',
    '#66b4ae',
    '#004162',
    '#feca76',
    '#512254',
    '#f8a602',
    '#e38b83',
    '#66b4ae',
    '#004162',
    '#feca76',
    '#512254',
    '#009aa8',
    '#ffb014',
    '#f8a602',
    '#e38b83',
    '#66b4ae',
    '#004162',
    '#feca76',
    '#512254',
    '#f8a602',
    '#e38b83',
    '#66b4ae',
    '#004162',
    '#feca76',
    '#512254'];